<template>
  <b-container fluid>
    <h3>{{ $t("page.communication.stepThree.readOption") }}:</h3>
    <b-form-checkbox
      id="readConfirmation"
      v-model="readConfirmation"
      name="readConfirmation"
    >
      <img src="../../../assets/conferm.svg" alt="v" style="height: 20px" />
      {{ $t("page.communication.stepThree.readRequest") }}
    </b-form-checkbox>
    <h3 class="pt-4">
      {{ $t("page.communication.stepThree.methodOfSending") }}
    </h3>
    <b-row>
      <b-col>
        <strong>
          {{ $t("page.communication.stepThree.totalRecipients") }}:
        </strong>
        {{ communication.recipients ? communication.recipients.length : "" }}
      </b-col>
      <b-col>
        <strong>{{ $t("page.communication.stepThree.sendViaApp") }}:</strong>
        {{ appCounter }}
        <b-form-checkbox
          id="emailToAppUsers"
          v-model="emailToAppUsers"
          name="emailToAppUsers"
          class="mt-4"
          @change="toggleEmail"
        >
          {{ $t("page.communication.stepThree.appCheckBox") }}
        </b-form-checkbox>
      </b-col>
      <b-col>
        <strong>{{ $t("page.communication.stepThree.sendViaMail") }}:</strong>
        {{ mailCounter }}
        <b-form-checkbox
          v-if="!$is1Board"
          id="paperToMailUsers"
          v-model="paperToMailUsers"
          name="paperToMailUsers"
          class="mt-4"
          @change="togglePapper"
        >
          {{ $t("page.communication.stepThree.mailCheckbox") }}
        </b-form-checkbox>
      </b-col>
      <b-col v-if="!$is1Board">
        <strong>{{ $t("page.communication.stepThree.papper") }}:</strong>
        {{ paperCounter }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { appMixin } from "./../../../mixins";

export default {
  mixins: [appMixin],
  data() {
    return {
      appCounter: 0,
      mailCounter: 0,
      initApp: 0,
      initMail: 0,
      paperCounter: 0
    };
  },
  mounted() {
    this.appCounter = this.communication.counters.appSent;
    this.mailCounter = this.communication.counters.mailSent;
    this.initApp = this.appCounter;
    this.initMail = this.mailCounter;
    if (this.paperToMailUsers)
      this.paperCounter = this.paperCompute + this.mailCounter;
    else this.paperCounter = this.paperCompute;
  },
  computed: {
    communication() {
      return this.$store.getters["communication/getCommunication"];
    },
    readConfirmation: {
      get() {
        return this.$store.getters["communication/getSettings"].readReceipt;
      },
      set(value) {
        this.$store.commit(
          "communication/SET_SETTINGS_READ_CONFIRMATION",
          value
        );
      }
    },
    emailToAppUsers: {
      get() {
        return this.$store.getters["communication/getSettings"].emailToAppUsers;
      },
      set(value) {
        this.$store.commit("communication/SET_SETTINGS_APP", value);
      }
    },
    paperToMailUsers: {
      get() {
        return this.$store.getters["communication/getSettings"]
          .paperToMailUsers;
      },
      set(value) {
        this.$store.commit("communication/SET_SETTINGS_PAPPER", value);
      }
    },
    paperCompute() {
      var counter =
        this.communication.recipients?.length - (this.initApp + this.initMail);
      if (counter >= 0) {
        return counter;
      }
      return 0;
    }
  },
  methods: {
    toggleEmail(checked) {
      if (checked) {
        this.mailCounter = this.appCounter + this.mailCounter;
      } else {
        if (this.mailCounter - this.appCounter < 0) {
          this.mailCounter = 0;
        } else {
          this.mailCounter = this.mailCounter - this.appCounter;
        }
      }
      if (this.paperToMailUsers) {
        this.paperCounter = this.paperCompute + this.mailCounter;
      }
    },
    togglePapper(checked) {
      if (checked) {
        this.paperCounter = this.paperCompute + this.mailCounter;
      } else {
        this.paperCounter = this.paperCompute;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
